import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../../services/user/auth.service';
import { PermissionService } from '../../services/permission/permission.service';

export const permissionGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService = inject(AuthService);
    const permissionService = inject(PermissionService);

    // Prüfen, ob der Benutzer eingeloggt ist und das Token nicht abgelaufen ist
    if (authService.isUserTokenExpired()) {
        window.location.href = '/login';
        return false;
    }

    // Berechtigung(en) aus den Routen-Daten holen (kann String oder Array sein)
    const requiredPermission = route.data['requiredPermission'];

    // Prüfen, ob der Benutzer isSuperAdmin ist
    if (permissionService.hasPermission('isSuperAdmin')) {
        return true;
    }

    // Prüfen, ob `requiredPermission` ein Array ist oder nicht
    if (Array.isArray(requiredPermission)) {
        // Überprüfen, ob der Benutzer eine der erforderlichen Berechtigungen hat
        return requiredPermission.some((permission: string) => permissionService.hasPermission(permission));
    } else {
        // Einzelne Berechtigung prüfen
        return permissionService.hasPermission(requiredPermission);
    }
};
