import { CanActivateFn } from '@angular/router';
import { AuthService } from '../../services/user/auth.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
	const authService = inject(AuthService);

	if(authService.isUserTokenExpired()){
		// console.log('can activate false');
		// console.log('zurück zum Start');
		window.location.href = '/login';
		return false;
	} else {
		return true;
	}
};
